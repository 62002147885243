import { Component } from 'react';
import { Link } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import configData from '../config.json';
import './PatternDesigner.css';

const MESSAGES_URL = configData.MESSAGES_URL;


class PatternDesigner extends Component {

    constructor(props) {
        super(props);
        this.state = {
          pattern: "candycane",
          colorRGB1: {r: 156, g: 8, b: 240},
          colorRGB2: {r: 241, g: 228, b: 100},
          colorRGB3: {r: 241, g: 228, b: 100},
          colorRGB4: {r: 241, g: 228, b: 100},
          colorRGB5: {r: 241, g: 228, b: 100},
          speed: 5
        };

        this.handlePatternChange = this.handlePatternChange.bind(this);
        this.handleSpeedChange = this.handleSpeedChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    postMessage(pattern, colours) {
        console.log("Custom Movie");
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                'movie': 'custom',
                'pattern': this.state.pattern,
                'colours': colours

            })
        };
        fetch(MESSAGES_URL, requestOptions)
            .then(response => response.json())
            .catch(error => {
                console.log(error);
            });
    }

    handlePatternChange(setPattern) {
        const target = setPattern.target;
        const value = target.value;
    
        this.setState({
          pattern: value
        });
    }

    handleSpeedChange(setSpeed) {
        const target = setSpeed.target;
        const value = target.value;
    
        this.setState({
          speed: value
        });
    }
    
    handleSubmit(event) {
        event.preventDefault();
        console.log('Pattern: ' + this.state.pattern);
        console.log('Color1: ' + JSON.stringify(this.state.colorRGB1));
        console.log('Color2: ' + JSON.stringify(this.state.colorRGB2));
        console.log('Color3: ' + JSON.stringify(this.state.colorRGB3));
        console.log('Color4: ' + JSON.stringify(this.state.colorRGB4));
        console.log('Color5: ' + JSON.stringify(this.state.colorRGB5));
        console.log('Speed: ' + this.state.speed);
        const colors = [
            this.state.colorRGB1,
            this.state.colorRGB2,
            this.state.colorRGB3,
            this.state.colorRGB4,
            this.state.colorRGB5
        ];
        this.postMessage(this.state.pattern, colors);
    }

    handleColor = (color, i) => {
        let colorRGB = "colorRGB" + i;

        if(color != null) {
            this.setState({
                [colorRGB]: color.rgb,
            });
        }
    };


    render() {
        return (
            <div className="container">
                <div className="padding">
                    <header className="d-flex justify-content-center padding" style={{backgroundColor: '#dce0dd'}}>
                        Choose a Pattern to Customise
                    </header>
                </div>
                <div className="padding">
                    <form onSubmit={this.handleSubmit} style={{backgroundColor: '#dce0dd'}}>
                        <div className="mb-3"> 
                            <Container fluid={true}>
                            <Row>
                                    <Col>
                                        Fading Colours
                                    </Col>
                                    <Col>
                                        Fading Rainbow
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Link to="/gradient">
                                            <img src="/gifs/thumbnails/wednesday.gif" id="gradient" alt="gradient" />
                                        </Link>
                                    </Col>
                                    <Col>
                                        <Link to="/fadingrainbow">
                                            <img src="/gifs/thumbnails/pale_rainbow.gif" id="fadingrainbow" alt="fadingrainbow" />
                                        </Link>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        Rainbow
                                    </Col>
                                    <Col>
                                        Candy Cane
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Link to="/rainbow">
                                            <img src="/gifs/thumbnails/pride.gif" id="3d_rainbow" alt="3D Rainbow" />
                                        </Link>
                                    </Col>
                                    <Col>
                                        <Link to="/candycane">
                                            <img src="/gifs/thumbnails/candy_cane.gif" id="candy_cane" alt="Candy Cane" />
                                        </Link>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        Glow
                                    </Col>
                                    <Col>
                                    Vertical Stripes
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Link to="/glow">
                                            <img src="/gifs/thumbnails/glow.gif" id="glow" alt="glow" />
                                        </Link>
                                    </Col>
                                    <Col>
                                        <Link to="/vertical_stripes">
                                            <img src="/gifs/thumbnails/italy.gif" id="vertical_stripes" alt="vertical_stripes" />
                                        </Link>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                    Sparkles
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Link to="/sparkles">
                                            <img src="/gifs/thumbnails/sparkles.gif" id="sparkles" alt="sparkles" />
                                        </Link>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
};

export default PatternDesigner;