import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import GalleryThumbs from './components/GalleryThumbs';
import PatternDesigner from './components/PatternDesigner';

import CandyCane from "./components/patterns/candycane";
import Rainbow from "./components/patterns/rainbow";
import Glow from './components/patterns/glow';
import VerticalStripes from './components/patterns/vertical_stripes';
import Sparkles from './components/patterns/sparkles';
import FadingAngle from './components/patterns/fadingangle';
import FadingVertical from './components/patterns/fadingvertical';


function App() {
  return (
    <div className="TreeAt53">
      <Router>
        <Routes>
            <Route path="/" element={<GalleryThumbs />} />
            <Route path="/custom" element={<PatternDesigner />} />
            <Route path="/candycane" element={<CandyCane />} />
            <Route path="/rainbow" element={<Rainbow />} />
            <Route path="/glow" element={<Glow />} />
            <Route path="/vertical_stripes" element={<VerticalStripes />} />
            <Route path="/sparkles" element={<Sparkles />} />
            <Route path="/gradient" element={<FadingAngle />} />
            <Route path="/fadingrainbow" element={<FadingVertical />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
