import React, { Component } from 'react';
import ColourButton from './ColourButton';

class ColourPickers extends Component {
    state = {
        colorRGB1: this.props.colorRGB1,
        colorRGB2: this.props.colorRGB2,
        colorRGB3: this.props.colorRGB3,
        colorRGB4: this.props.colorRGB4,
        colorRGB5: this.props.colorRGB5,
    }


    handleColor = (color, i) => {
        let colorRGB = "colorRGB" + i;

        if(color != null) {
            this.setState({
                [colorRGB]: color.rgb,
            });
        }
        this.props.handleColor(color, i);
    };

    getColorPickers = () => {
        let colorPickers = []

        for (let i = 1; i <= this.props.colorCount; i++) {
            let color = "colorRGB" + i;
            colorPickers.push(
                <div key={i} className="form-group">
                        <ColourButton color={this.state[color]}
                                     key={i}
                                     handleColorChange={event => this.handleColor(event, i)}/>
                </div>);
        }
        return colorPickers;
    }

    render() {
        return (
            <div className="container form-container">
                    {this.getColorPickers()}
            </div>
        );
    }
}

export default ColourPickers;