import React, { useState } from 'react';
import {useNavigate} from "react-router-dom"
import ColourPickers from '../ColourPickers';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import configData from '../../config.json';

const MESSAGES_URL = configData.MESSAGES_URL;

function FadingAngle() {
    const pattern = "gradient";
    const [colorRGB1, setColorRGB1] = useState({r: 0, g: 255, b: 0})
    const [colorRGB2, setColorRGB2] = useState({r: 255, g: 255, b: 0})
    const [colorRGB3, setColorRGB3] = useState({r: 0, g: 255, b: 0})
    const [colorRGB4, setColorRGB4] = useState({r: 255, g: 255, b: 0})
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const navigate = useNavigate();

    const openModal = e => { setModalIsOpen(true) }    
    const closeModal = e => { 
        setModalIsOpen(false);
        navigate('/');
    }

    const postMessage = (pattern, colours) => {
        console.log("Custom Movie");
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                'movie': 'custom',
                'pattern': pattern,
                'colours': colours,
                'speed': 0.04,
                'angle': 45
            })
        };
        fetch(MESSAGES_URL, requestOptions)
            .then(response => response.json())
            .catch(error => {
                console.log(error);
            });
            
        openModal();
    }
    
    const handleSubmit = event => {
        event.preventDefault();
        console.log('Pattern: ' + pattern);
        console.log('Color1: ' + JSON.stringify(colorRGB1));
        console.log('Color2: ' + JSON.stringify(colorRGB2));
        console.log('Color3: ' + JSON.stringify(colorRGB3));
        console.log('Color4: ' + JSON.stringify(colorRGB4));
        const colors = [
            colorRGB1,
            colorRGB2,
            colorRGB3,
            colorRGB4
        ];
        postMessage(pattern, colors);
    }

    const handleColor = (color, i) => {
        let colorRGB = "colorRGB" + i;

        if(color != null) {
            if(colorRGB === "colorRGB1"){
                setColorRGB1(color.rgb);
            } else if(colorRGB === "colorRGB2") {
                setColorRGB2(color.rgb);
            } else if(colorRGB === "colorRGB3") {
                setColorRGB3(color.rgb);
            } else if(colorRGB === "colorRGB4") {
                setColorRGB4(color.rgb);
            }
        }
    };


    return (
        <div className="container">
            <div className="padding">
                <header className="d-flex justify-content-center padding" style={{backgroundColor: '#dce0dd'}}>
                    Fading Colours Angled
                </header>
            </div>
            <div className="padding">
                <form onSubmit={handleSubmit} style={{backgroundColor: '#dce0dd'}}>
                    <div className="mb-3">
                        <label className="form-label">Choose your colours</label>
                        <ColourPickers handleColor={handleColor} colorCount="4" colorRGB1={colorRGB1} colorRGB2={colorRGB2} colorRGB3={colorRGB3} colorRGB4={colorRGB4}/>
                    </div>
                    <div className="mb-3">
                        <input type="submit" value="Submit" />
                    </div>
                </form>
            </div>
            <Modal show={modalIsOpen} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Lights Request Sent</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Please wait up to 30 seconds for the lights to update before trying again :)
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default FadingAngle;