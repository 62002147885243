import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal'

import configData from '../config.json';
import './PatternDesigner.css';


const MESSAGES_URL = configData.MESSAGES_URL;


class GalleryThumbs extends Component {
    constructor(props) {
        super(props);
        this.state = {
          activeItem: {},
          modalIsOpen: false
        };
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.postMessage = this.postMessage.bind(this);
    }

    openModal() { this.setState({ modalIsOpen: true }); }
    closeModal() { this.setState({ modalIsOpen: false }); }

    postMessage(e) {
        console.log(e.target.id)
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'movie': e.target.id })
        };
        fetch(MESSAGES_URL, requestOptions)
            .then(response => response.json())
            .catch(error => {
                console.log(error);
            });
        this.setState({ modalIsOpen: true });
    }


    render() {
        return (
            <>
                <div className="container">
                    <header className="d-flex justify-content-center padding" style={{backgroundColor: '#000000', color: '#ffffff'}}>
                        Welcome to Tree53!
                    </header>
                </div>
                <div className="text-center padding">
                    <Link to="/custom">
                        <Button variant="primary btn-block" size="lg">Click Here to Create Custom Design</Button>{' '}
                    </Link>
                </div>
                <div className="container padding">
                    <header className="d-flex justify-content-center padding" style={{backgroundColor: '#000000', color: '#ffffff'}}>
                       Default Patterns 
                    </header>
                </div>
                <div>  
                    <Container fluid={true}>
                        <Row>
                            <Col xs={4}>
                                <img src="/gifs/thumbnails/24k.gif" id="24k" onClick={this.postMessage} alt="24k" style={{width: "110px", height: "110px"}} />
                            </Col>
                            <Col xs={4}>
                                <img src="/gifs/thumbnails/bliss.gif" id="bliss" onClick={this.postMessage} alt="Bliss" style={{width: "110px", height: "110px"}} />
                            </Col>
                            <Col xs={4}>
                                <img src="/gifs/thumbnails/candy_floss.gif" id="candy_floss" onClick={this.postMessage} alt="Candy Floss" style={{width: "110px", height: "110px"}} />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={4}>
                                <img src="/gifs/thumbnails/christmas.gif" id="christmas" onClick={this.postMessage} alt="Christmas" style={{width: "110px", height: "110px"}} />
                            </Col>
                            <Col xs={4}>
                                <img src="/gifs/thumbnails/paradise.gif" id="paradise" onClick={this.postMessage} alt="Paradise" style={{width: "110px", height: "110px"}} />
                            </Col>
                            <Col xs={4}>
                                <img src="/gifs/thumbnails/solar.gif" id="solar" onClick={this.postMessage} alt="Solar" style={{width: "110px", height: "110px"}} />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={4}>
                                <img src="/gifs/thumbnails/tartan.gif" id="tartan" onClick={this.postMessage} alt="Tartan" style={{width: "110px", height: "110px"}} />
                            </Col>
                            <Col xs={4}>
                                <img src="/gifs/thumbnails/tropical.gif" id="tropical" onClick={this.postMessage} alt="Tropical" style={{width: "110px", height: "110px"}} />
                            </Col>
                            <Col xs={4}>
                                <img src="/gifs/thumbnails/valentine.gif" id="valentine" onClick={this.postMessage} alt="Valentine" style={{width: "110px", height: "110px"}} />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={4}>
                                <img src="/gifs/thumbnails/carnival.gif" id="carnival" onClick={this.postMessage} alt="Carnival" />
                            </Col>
                            <Col xs={4}>
                                <img src="/gifs/thumbnails/pale_rainbow.gif" id="pale_rainbow" onClick={this.postMessage} alt="Pale Rainbow" />
                            </Col>
                            <Col xs={4}>
                                <img src="/gifs/thumbnails/wednesday.gif" id="wednesday" onClick={this.postMessage} alt="Wednesday" />
                            </Col>
                        </Row>
                        <Row>
                        <Col xs={4}>
                                <img src="/gifs/thumbnails/3d_rainbow.gif" id="3d_rainbow" onClick={this.postMessage} alt="3D Rainbow" />
                            </Col>
                            <Col xs={4}>
                                <img src="/gifs/thumbnails/fireworks.gif" id="fireworks" onClick={this.postMessage} alt="Fireworks" />
                            </Col>
                            <Col xs={4}>
                                <img src="/gifs/thumbnails/fixed.gif" id="fixed" onClick={this.postMessage} alt="Fixed" />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={4}>
                                <img src="/gifs/thumbnails/flare.gif" id="flare" onClick={this.postMessage} alt="Flare" />
                            </Col>
                            <Col xs={4}>
                                <img src="/gifs/thumbnails/france.gif" id="france" onClick={this.postMessage} alt="France" />
                            </Col>
                            <Col xs={4}>
                                <img src="/gifs/thumbnails/germany.gif" id="germany" onClick={this.postMessage} alt="Germany" />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={4}>
                                <img src="/gifs/thumbnails/glow.gif" id="glow" onClick={this.postMessage} alt="Glow" />
                            </Col>
                            <Col xs={4}>
                                <img src="/gifs/thumbnails/greece.gif" id="greece" onClick={this.postMessage} alt="Greece" />
                            </Col>
                            <Col xs={4}>
                                <img src="/gifs/thumbnails/italy.gif" id="italy" onClick={this.postMessage} alt="Italy" />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={4}>
                                <img src="/gifs/thumbnails/magic_rainbow.gif" id="magic_rainbow" onClick={this.postMessage} alt="Magic Rainbow" />
                            </Col>
                            <Col xs={4}>
                                <img src="/gifs/thumbnails/mint.gif" id="mint" onClick={this.postMessage} alt="Mint" />
                            </Col>
                            <Col xs={4}>
                                <img src="/gifs/thumbnails/night_sky.gif" id="night_sky" onClick={this.postMessage} alt="Night Sky" />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={4}>
                                <img src="/gifs/thumbnails/bright_twinkle.gif" id="bright_twinkle" onClick={this.postMessage} alt="Bright Twinkle" />
                            </Col>
                            <Col xs={4}>
                                <img src="/gifs/thumbnails/peachy.gif" id="peachy" onClick={this.postMessage} alt="Peachy" />
                            </Col>
                            <Col xs={4}>
                                <img src="/gifs/thumbnails/poland.gif" id="poland" onClick={this.postMessage} alt="Poland" />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={4}>
                                <img src="/gifs/thumbnails/pride.gif" id="pride" onClick={this.postMessage} alt="Pride" />
                            </Col>
                            <Col xs={4}>
                                <img src="/gifs/thumbnails/rainbow_stripes.gif" id="rainbow_stripes" onClick={this.postMessage} alt="Rainbow Stripes" />
                            </Col>
                            <Col xs={4}>
                                <img src="/gifs/thumbnails/rainbow.gif" id="rainbow" onClick={this.postMessage} alt="Rainbow" />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={4}>
                                <img src="/gifs/thumbnails/sherbert.gif" id="sherbert" onClick={this.postMessage} alt="Sherbert" />
                            </Col>
                            <Col xs={4}>
                                <img src="/gifs/thumbnails/snake.gif" id="snake" onClick={this.postMessage} alt="Snake" />
                            </Col>
                            <Col xs={4}>
                                <img src="/gifs/thumbnails/snow.gif" id="snow" onClick={this.postMessage} alt="Snow" />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={4}>
                                <img src="/gifs/thumbnails/sparkles.gif" id="sparkles" onClick={this.postMessage} alt="Sparkles" />
                            </Col>
                            <Col xs={4}>
                                <img src="/gifs/thumbnails/sunset.gif" id="sunset" onClick={this.postMessage} alt="Sunset" />
                            </Col>
                            <Col xs={4}>
                                <img src="/gifs/thumbnails/unicorn_sun.gif" id="unicorn_sun" onClick={this.postMessage} alt="Unicorn Sun" />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={4}>
                                <img src="/gifs/thumbnails/up_and_down.gif" id="up_and_down" onClick={this.postMessage} alt="Up and Down" />
                            </Col>
                            <Col xs={4}>
                                <img src="/gifs/thumbnails/vintage.gif" id="vintage" onClick={this.postMessage} alt="Vintage" />
                            </Col>
                            <Col xs={4}>
                                <img src="/gifs/thumbnails/waves.gif" id="waves" onClick={this.postMessage} alt="Waves" />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={4}>
                                <img src="/gifs/thumbnails/candy_cane.gif" id="candy_cane" onClick={this.postMessage} alt="Candy Cane" />
                            </Col>
                        </Row>
                    </Container>
                    <Modal show={this.state.modalIsOpen} onHide={this.closeModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Lights Request Sent</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Please wait up to 30 seconds for the lights to update before trying again :)
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.closeModal}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </>
        );
    }
}

export default GalleryThumbs
