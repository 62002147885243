import React from 'react'
import reactCSS from 'reactcss'
import { SwatchesPicker } from 'react-color'

class ColourButton extends React.Component {
  state = {
    displayColorPicker: false,
    color: this.props.color,
  };

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false })
  };

  handleChange = (color) => {
    this.setState({ color: color.rgb })
    this.props.handleColorChange(color);
  };

  render() {
    const styles = reactCSS({
      'default': {
        color: {
          width: '36px',
          height: '14px',
          borderRadius: '2px',
          background: `rgba(${ this.state.color.r }, ${ this.state.color.g }, ${ this.state.color.b })`,
        },
        swatch: {
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
      },
    });

    return (
      <div>
        <div style={ styles.swatch } onClick={ this.handleClick }>
          <div style={ styles.color } />
        </div>
        { this.state.displayColorPicker ? <div style={ styles.popover }>
          <div style={ styles.cover } onClick={ this.handleClose }/>
          <SwatchesPicker color={ this.state.color } onChange={ this.handleChange }
            colors={[
              ['#ff0000', '#fc3535', '#fc5858', '#cf0202'], 
              ['#ff5900', '#fc6f23', '#ff8847', '#bf4400'], 
              ['#ffd500', '#fcd821', '#fcdd3d', '#d1b002'],
              ['#ffff00', '#fcfc4c', '#dbdb16', '#baba00'],
              ['#84ff00', '#94f72a', '#a7fc4c', '#66c402'],
              ['#00ff6e', '#41fa91', '#13d668', '#02ba52'],
              ['#00ffe5', '#43fae7', '#00c7b2', '#009c8b'],
              ['#00bfff', '#42d0ff', '#0296c7', '#027196'],
              ['#0033ff', '#385ffc', '#0029cf', '#0020a3'],
              ['#4316b5', '#5630b8', '#2d0a8a', '#1c0263'],
              ['#d000ff', '#d93dfc', '#9202b3', '#66027d'],
              ['#ff00cc', '#fc3ad6', '#c4009e', '#910175'],
              ['#ff0062', '#fc3f88', '#bd024a', '#96023b'],
            ]}
          />
        </div> : null }

      </div>
    )
  }
}

export default ColourButton